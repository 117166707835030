import { CSSProperties, FC, ReactNode } from 'react';

import * as Styled from './styled';

type TProps = {
    children: ReactNode;
    styled?: CSSProperties;
};

export const ContainerContent: FC<TProps> = ({ styled, children }) => {
    return <Styled.Wrapper style={styled}>{children}</Styled.Wrapper>;
};
