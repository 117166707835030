import { useFormik } from 'formik';
import Image from 'next/image';
import React, { useState } from 'react';
import * as Yup from 'yup';

import { PATH_PUBLIC_BASE_URL } from '@/shared/config/app';
import { PHONE_LENGTH } from '@/shared/constants/settings';
import { useTranslate } from '@/shared/hooks/use-translate';
import { useUtmParams } from '@/shared/hooks/use-utm-params';
import { customCaptureException } from '@/shared/lib/custom-capture-exception';
import { Button } from '@/shared/ui/button';
import { InputField } from '@/shared/ui/input/input-field';

import { sendQuestionnairesForm } from '../api';
import CallCenterEmployeeImage from '../images/call-center-employee.png';

import * as Styled from './styled';

export const OrderCallForm = () => {
    const [isSubmitLoaded, setIsSubmitLoaded] = useState(false);
    const [result, setResult] = useState<null | { data: boolean }>(null);

    const { getUtmParamsFromCookie } = useUtmParams();

    const { t } = useTranslate();

    const formik = useFormik({
        initialValues: { phone: '' },
        onSubmit: async (values) => {
            setIsSubmitLoaded(true);
            try {
                const utmStaff = getUtmParamsFromCookie();

                const dataForm = {
                    message: '',
                    name: 'Заявка с формы в шапке',
                    phone: values?.phone.replace(/[^\d+]/g, ''),
                    referal: utmStaff.referal as string,
                    utm: utmStaff.utm_source,
                };
                const response = await sendQuestionnairesForm(dataForm);
                setResult({ data: response });
            } catch (e) {
                customCaptureException(e, 'OrderCallModal => formik => onSubmit');
                setResult({ data: false });
            }

            setIsSubmitLoaded(false);
        },
        validateOnBlur: true,
        validateOnChange: true,
        validateOnMount: true,
        validationSchema: Yup.object().shape({
            phone: Yup.string().test('phone', 'Невалидный номер', (value) => {
                return value?.replace(/\D+/g, '').length === PHONE_LENGTH;
            }),
        }),
    });

    if (result?.data) {
        return (
            <Styled.Wrapper>
                <Image
                    alt="Call center employee"
                    className="employee-image"
                    height={440}
                    src={CallCenterEmployeeImage}
                />

                <div className="content">
                    <div className="content-success">
                        <div className="title">{t('Спасибо за ваше обращение')}</div>

                        <div className="text">{t('Мы получили вашу заявку')}</div>

                        <div className="sub-text">
                            {t(
                                'Специалист ломбарда свяжется с вами по указанному номеру в течение 7 минут, чтобы подтвердить заявку',
                            )}
                        </div>
                    </div>
                </div>
            </Styled.Wrapper>
        );
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <Styled.Wrapper>
                <Image
                    alt="Call center employee"
                    className="employee-image"
                    height={440}
                    src={CallCenterEmployeeImage}
                />

                <div className="content">
                    <div className="content-up-section">
                        <div className="flex-col-center">
                            <div className="content-title">{t('orderCallTitle')}</div>

                            <div className="content-text">{t('orderCallText')}</div>
                        </div>

                        <div className="phone-input">
                            <InputField
                                borderFocus
                                mask="+7 (000) 000-00-00"
                                name="phone"
                                onChange={formik.handleChange}
                                placeholder="+7 (000) 000-00-00"
                                size="lg"
                                value={formik.values.phone}
                            />
                        </div>
                    </div>

                    <div className="content-down-section">
                        <div className="button-order-call">
                            <Button
                                className="button-text"
                                disabled={isSubmitLoaded || !formik.isValid}
                                isLoading={isSubmitLoaded}
                                type="submit"
                                variant="gradient-brand-green"
                            >
                                {t('Позвоните мне')}
                            </Button>
                        </div>

                        <div className="notation">
                            {t('orderCallNotation', {
                                a: (chunks: string) => (
                                    <a
                                        href={`${PATH_PUBLIC_BASE_URL}/uploads/userfiles/myResource/politika-konf.pdf`}
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        {chunks}
                                    </a>
                                ),
                            })}
                        </div>
                    </div>
                </div>
            </Styled.Wrapper>
        </form>
    );
};
