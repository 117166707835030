import { DENGIMARKET_LOMBARD_INSTAGRAM_URL } from '@/shared/config/app';
import { TELEGRAM_LINK, WHATSAPP_LINK } from '@/shared/constants/settings';
import InstagramIcon from '@/shared/icons/instagram-icon';
import TelegramIcon from '@/shared/icons/telegram-icon';
import WhatsappIcon from '@/shared/icons/whatsapp-icon';

import { TSocialLinks } from './types';

export const socialLinks: TSocialLinks[] = [
    {
        Icon: InstagramIcon,
        href: DENGIMARKET_LOMBARD_INSTAGRAM_URL,
        type: 'Instagram',
    },
    {
        Icon: WhatsappIcon,
        href: WHATSAPP_LINK,
        type: 'Whatsapp',
    },
    {
        Icon: TelegramIcon,
        href: TELEGRAM_LINK,
        type: 'Telegram',
    },
];
