import { styled } from 'styled-components';

import { mq } from '@/styles/media.styled';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: start;
    gap: 1.56rem;

    ${mq.lt.lg} {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: 1.5rem;
        height: 100%;
    }
`;
