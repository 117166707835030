import React from 'react';

import { TSvgIcon } from '@/shared/types/types';

const TelegramIcon: TSvgIcon = (props) => {
    const { height = 28, width = 28 } = props;

    return (
        <svg fill="none" height={height} viewBox="0 0 29 24" width={width} xmlns="http://www.w3.org/2000/svg">
            <path
                clipRule="evenodd"
                d="M13.9362 5.06115C7.00745 7.78285 1.16243 10.0849 0.947366 10.1773C0.731706 10.27 0.597031 10.6908 0.647422 11.1155C0.731406 11.8243 1.01965 11.9683 4.24736 12.9119C7.75581 13.938 7.75581 13.938 8.54107 16.5274C9.88152 20.9498 10.3062 22.0089 10.8554 22.3032C11.5564 22.6781 12.0969 22.3587 13.9248 20.4891C14.7436 19.652 15.5256 18.9669 15.6624 18.9669C15.7994 18.9669 17.3567 20.0461 19.1231 21.3652C20.8895 22.6841 22.6352 23.7639 23.0026 23.7648C23.954 23.7666 24.4642 22.7822 25.0236 19.8667C25.2927 18.4645 25.8341 15.8325 26.2264 14.0178C28.4523 3.72579 28.9079 1.03169 28.5051 0.546379C28.2879 0.284528 27.7555 0.0799652 27.3221 0.0913631C26.8884 0.103061 20.8649 2.33945 13.9362 5.06115ZM23.0845 5.22342C22.6721 5.55456 21.3223 6.73844 20.085 7.85423C18.8478 8.97003 16.3963 11.1356 14.6374 12.6665C11.4391 15.45 11.4391 15.45 11.2127 17.5835C10.9859 19.7167 10.9859 19.7167 10.1809 17.1672C9.73815 15.765 9.28493 14.3318 9.17395 13.9821C8.99518 13.4206 9.52188 13.0064 13.7034 10.4197C16.306 8.80986 19.5802 6.77653 20.9798 5.90099C23.4327 4.36677 24.6325 3.98045 23.0845 5.22342Z"
                fill="white"
                fillRule="evenodd"
            />
        </svg>
    );
};

export default TelegramIcon;
