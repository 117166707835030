import { styled } from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 0 12px;

    .links-section {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        gap: 21px;
        margin-top: 32px;

        .mr-6 {
            margin-right: 6px;
        }

        .action-button-text:hover,
        a:hover {
            color: #14ae51;
        }
    }

    .login-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        width: 100%;

        .login-link {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
        }

        .notation-text {
            font-weight: 500;
            font-size: 12px;
            line-height: 130%;
            text-align: center;
            color: #818c99;
            width: 100%;
            max-width: 270px;
        }
    }

    .action-button-text {
        font-weight: 500;
        font-size: 14px;
        line-height: 16.8px;
    }

    .locale-switcher-section {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        padding: 32px 32px 16px;
    }
`;
