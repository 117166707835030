import { useRouter } from 'next/router';
import React, { FC, useMemo } from 'react';

import { PUBLIC_PHONE_NUMBER } from '@/shared/config/app';
import { useTranslate } from '@/shared/hooks/use-translate';
import { LayoutDefault } from '@/shared/ui/layout/default';
import { LinkText } from '@/shared/ui/link-text';
import { ELinkTextVariantType } from '@/shared/ui/link-text/types';

import { socialLinks } from '../constants';
import { prepareSocialLinks } from '../lib';

import { FooterNavBar } from './footer-nav-bar';
import { LogoTitleSection } from './logo-title-section';
import * as Styled from './styled';

type TProps = {
    phone?: string;
};

export const Footer: FC<TProps> = ({ phone = PUBLIC_PHONE_NUMBER }) => {
    const { t } = useTranslate();

    const router = useRouter();
    const _phone = (phone ?? PUBLIC_PHONE_NUMBER) as string;

    const links = useMemo(() => prepareSocialLinks(socialLinks, _phone.replace(/[^0-9]/g, '')), [_phone]);

    return (
        <Styled.Wrapper>
            <LayoutDefault.Content>
                <Styled.RowWrapper>
                    <Styled.ContentWrapper>
                        <LogoTitleSection />
                        <div className="socials">
                            {links.map(({ Icon, href }) => (
                                <LinkText
                                    className="small-footer-btn"
                                    href={href}
                                    key={href}
                                    target="_blank"
                                    variant={ELinkTextVariantType.GRADIENT_BRAND_GREEN}
                                >
                                    <Icon />
                                </LinkText>
                            ))}
                        </div>
                    </Styled.ContentWrapper>
                    <Styled.ContentWrapper>
                        <FooterNavBar />
                        <div className={`phone ${router.locale}`}>
                            <div className="bold">{t('Есть вопросы?')}</div>
                            <div>
                                {t('footerOpeningHours', {
                                    a: (chunks: string) => (
                                        <a className="text-main-color" href={`tel:+${_phone.replace(/[^0-9]/g, '')}`}>
                                            {chunks}
                                        </a>
                                    ),
                                    phone: _phone,
                                })}
                            </div>
                        </div>
                    </Styled.ContentWrapper>
                    <Styled.ContentWrapper reverse="true">
                        <div className="copyrights">
                            <div>{t('footerCopyrights1')}</div>
                            <div>{t('footerCopyrights2')}</div>
                            <div>{t('footerCopyrights3')}</div>
                        </div>
                        <div className="address">
                            <div className="medium">{t('Юридический и фактический адрес')}</div>
                            <div>{t('footerCompanyAddress')}</div>
                        </div>
                    </Styled.ContentWrapper>
                </Styled.RowWrapper>
            </LayoutDefault.Content>
        </Styled.Wrapper>
    );
};
