import Link from 'next/link';
import { styled } from 'styled-components';

import { mq } from '@/styles/media.styled';

export const Wrapper = styled.footer`
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.color.secondary};
    font-size: ${({ theme }) => theme.fontSize.sm};
    padding-top: 50px;
    padding-bottom: 50px;

    ${mq.lt.lg} {
        padding-top: 32px;
        padding-bottom: 32px;
    }
`;

export const RowWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2.37rem;
`;

export const ContentWrapper = styled.div<{ reverse?: string }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    ${mq.lt.lg} {
        display: flex;
        flex-direction: ${(reverse) => (reverse ? 'column-reverse' : 'column')};
        justify-content: start;
        gap: 42px;
        height: 100%;
        min-width: 0;
        width: 100%;
    }

    .small-footer-btn {
        ${mq.lt.lg} {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 100%;
        }
    }

    .copyrights {
        max-width: 23rem;
        font-weight: 400;
        font-size: ${({ theme }) => theme.fontSize.xs};
        line-height: 120%;

        ${mq.lt.lg} {
            text-align: center;
            line-height: 1.5rem;
        }
    }

    .socials {
        display: flex;
        gap: 12px;
    }

    .bold {
        font-weight: 700;
    }

    .medium {
        font-weight: 500;
    }

    .phone {
        display: flex;
        flex-direction: column;
        align-items: end;
        gap: 4px;
        font-weight: 500;
        font-size: ${({ theme }) => theme.fontSize.xs};
        line-height: 120%;
        text-align: right;

        .text-main-color {
            font-weight: bold;
            background: linear-gradient(218.13deg, #63d07b 18.93%, #0bb6ba 94.11%);
            background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        ${mq.lt.lg} {
            align-items: center;
            text-align: center;
        }
    }

    .ru {
        max-width: 11.5rem;
    }

    .kk {
        max-width: 244px;
    }

    .address {
        display: flex;
        flex-direction: column;
        align-items: end;
        gap: 4px;
        max-width: 22.6rem;
        font-weight: 400;
        font-size: ${({ theme }) => theme.fontSize.xs};
        line-height: 120%;
        text-align: right;

        ${mq.lt.lg} {
            align-items: center;
            text-align: center;
            gap: 0.5rem;
        }
    }
`;

export const StyledDropdownDocumentLink = styled(Link)`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 400;
    font-size: ${({ theme }) => theme.fontSize.sm};
    line-height: 120%;
    color: ${({ theme }) => theme.color.primary};
    width: 100%;
    gap: 0.75rem;

    .mr-12 {
        margin-right: 0.75rem;
    }

    &:hover {
        color: ${({ theme }) => theme.color.primary};
    }
`;

export const StyledDropdownDocumentsList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
`;
