import Image from 'next/image';
import React, { FC } from 'react';

import logoCompact from 'public/logo-header-compact.svg';
import logo from 'public/logo-header.svg';

import * as Styled from './styled';

type THeaderLogo = {
    className?: string;
    height?: number;
    href: string;
    onClick?: () => void;
    width?: number;
    alwaysDefault?: boolean;
};

export const HeaderLogo: FC<THeaderLogo> = (props) => {
    const { className, height, href, onClick, width, alwaysDefault } = props;
    return (
        <Styled.LinkWrapper href={href} shallow>
            <Image
                alt="logo dengimarket"
                className={'default ' + className + (alwaysDefault ? ' alwaysDefault' : '')}
                height={height}
                onClick={onClick}
                src={logo}
                width={width}
            />
            {!alwaysDefault && (
                <Image
                    alt="logo dengimarket"
                    className={'compact ' + className}
                    height={height}
                    onClick={onClick}
                    src={logoCompact}
                    width={width}
                />
            )}
        </Styled.LinkWrapper>
    );
};
