import { styled } from 'styled-components';

import { mq } from '@/styles/media.styled';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 18.8rem;

    ${mq.lt.lg} {
        max-width: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }

    .title-text {
        font-weight: 400;
        font-size: ${({ theme }) => theme.fontSize.sm};
        line-height: 120%;
        margin-left: 1rem;
    }
`;
