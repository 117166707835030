import { WHATSAPP_LINK } from '@/shared/constants/settings';

import { TSocialLinks } from '../types';

export const prepareSocialLinks = (links: TSocialLinks[], phone: string): TSocialLinks[] => {
    return links.map((item) => {
        switch (item.type) {
            case 'Whatsapp':
                item.href = `${WHATSAPP_LINK}/+${phone}`;
                return item;
            default:
                return item;
        }
    });
};
