import { Link } from 'react-scroll';
import { styled, css } from 'styled-components';

import { mq } from '@/styles/media.styled';

import { TVariantScroll } from './index';

const titleSmall = css`
    font-weight: 500;
    font-size: ${({ theme }) => theme.fontSize.sm};
    line-height: 120%;
    text-align: center;
`;

const titleBrandGreen = css`
    ${mq.gt.sm} {
        font-weight: 500;
        font-size: ${({ theme }) => theme.fontSize.sm};
        line-height: 120%;
        background: linear-gradient(218.13deg, #63d07b 18.93%, #0bb6ba 94.11%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    ${mq.lt.lg} {
        color: ${({ theme }) => theme.color.dark};
        font-size: 14px;
        width: 79px;
        overflow: hidden;
        white-space: nowrap;
    }
`;

export const Wrapper = styled(Link)<{ variant?: TVariantScroll }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;

    &:hover {
        color: black;
    }

    &:active {
        color: black;
    }

    .location-logo {
        display: flex;
        align-items: center;
        margin-right: ${({ variant }) => (variant === 'gradient-brand-green' ? '0.25rem' : '15px')};
    }

    .location-title {
        ${({ variant }) => variant === 'gradient-brand-green' && titleBrandGreen};
        ${({ variant }) => variant === 'simple' && titleSmall};
    }
`;
