import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { FC } from 'react';

import * as Styled from './styled';

type TProps = {
    activeLocale: string;
    className?: string;
    locales: string[];
    namedLocale: { [key: string]: string };
};

export const LocaleSwitcher: FC<TProps> = ({ activeLocale, className, locales, namedLocale }) => {
    const { asPath, pathname, query } = useRouter();

    return (
        <Styled.Wrapper className={`switch-lang ${className}`}>
            {(locales as string[]).map((lang) => {
                return (
                    <Styled.LangWrapper isActive={activeLocale === lang} key={lang}>
                        <Link
                            as={asPath}
                            href={{
                                pathname,
                                query,
                            }}
                            locale={lang}
                        >
                            <span>{namedLocale[lang]}</span>
                        </Link>
                    </Styled.LangWrapper>
                );
            })}
        </Styled.Wrapper>
    );
};
