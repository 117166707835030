import { styled } from 'styled-components';

import { mq } from '@/styles/media.styled';

export const Wrapper = styled.header`
    position: sticky;
    top: 0;
    left: 0;
    z-index: 8;
    background-color: ${({ theme }) => theme.color.secondary};
    box-shadow:
        0 0 2px rgb(0 0 0 / 3%),
        0 2px 2px rgb(0 0 0 / 6%);

    ${mq.lt.md} {
        min-width: 0;
        width: 100%;
        padding: 16px 0;
    }
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 15px 0;

    ${mq.lt.md} {
        padding: 0;
    }

    .switch-lang {
        ${mq.lt.xl} {
            display: none;
        }
    }
`;
