import React from 'react';

import { TSvgIcon } from '@/shared/types/types';

const LocationIcon: TSvgIcon = (props) => {
    const { height = 20, width = 21 } = props;

    return (
        <svg fill="none" height={height} viewBox="0 0 21 20" width={width} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.2436 1.66675C7.01855 1.66675 4.41022 4.27508 4.41022 7.50008C4.41022 11.8751 10.2436 18.3334 10.2436 18.3334C10.2436 18.3334 16.0769 11.8751 16.0769 7.50008C16.0769 4.27508 13.4686 1.66675 10.2436 1.66675ZM6.07688 7.50008C6.07688 5.20008 7.94355 3.33341 10.2436 3.33341C12.5436 3.33341 14.4102 5.20008 14.4102 7.50008C14.4102 9.90008 12.0102 13.4917 10.2436 15.7334C8.51022 13.5084 6.07688 9.87508 6.07688 7.50008Z"
                fill="url(#paint0_linear_704_1846)"
            />

            <path
                d="M10.2436 9.58341C11.3941 9.58341 12.3269 8.65067 12.3269 7.50008C12.3269 6.34949 11.3941 5.41675 10.2436 5.41675C9.09296 5.41675 8.16022 6.34949 8.16022 7.50008C8.16022 8.65067 9.09296 9.58341 10.2436 9.58341Z"
                fill="url(#paint1_linear_704_1846)"
            />

            <defs>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint0_linear_704_1846"
                    x1="13.5769"
                    x2="2.46672"
                    y1="4.49413"
                    y2="14.402"
                >
                    <stop stopColor="#63D07B" />

                    <stop offset="1" stopColor="#0BB6BA" />
                </linearGradient>

                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint1_linear_704_1846"
                    x1="11.434"
                    x2="8.71825"
                    y1="6.12359"
                    y2="9.58341"
                >
                    <stop stopColor="#63D07B" />

                    <stop offset="1" stopColor="#0BB6BA" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default LocationIcon;
