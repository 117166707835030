import Image from 'next/image';
import React, { FC } from 'react';

import logo from 'public/logo-header.svg';

import * as Styled from './styled';

type TProps = {
    onClick?: () => void;
};

export const FooterLogo: FC<TProps> = ({ onClick }) => {
    return (
        <Styled.LinkWrapper href="/">
            <Image alt="Footer logo dengimarket" height={39} onClick={onClick} src={logo} width={154} />
        </Styled.LinkWrapper>
    );
};
