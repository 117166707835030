import React from 'react';

import { TSvgIcon } from '@/shared/types/types';

const BurgerMenuIcon: TSvgIcon = (props) => {
    const { height = 24, width = 24 } = props;

    return (
        <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4 18H20C20.55 18 21 17.55 21 17C21 16.45 20.55 16 20 16H4C3.45 16 3 16.45 3 17C3 17.55 3.45 18 4 18ZM4 13H20C20.55 13 21 12.55 21 12C21 11.45 20.55 11 20 11H4C3.45 11 3 11.45 3 12C3 12.55 3.45 13 4 13ZM3 7C3 7.55 3.45 8 4 8H20C20.55 8 21 7.55 21 7C21 6.45 20.55 6 20 6H4C3.45 6 3 6.45 3 7Z"
                fill="url(#paint0_linear_1009_2447)"
            />

            <defs>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint0_linear_1009_2447"
                    x1="17.1429"
                    x2="10.5274"
                    y1="8.03571"
                    y2="20.6776"
                >
                    <stop stopColor="#63D07B" />

                    <stop offset="1" stopColor="#0BB6BA" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default BurgerMenuIcon;
