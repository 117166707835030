import Image from 'next/image';
import { FC } from 'react';

import location from './assets/location.png';
import phone from './assets/phone.png';
import shop from './assets/shop-basket.png';

const iconList = {
    phone: phone,
    location: location,
    shop: shop,
};

type IProps = {
    icon: keyof typeof iconList;
    size?: 8 | 12 | 16 | 20 | 24;
};

export const GradientPngIcon: FC<IProps> = ({ icon, size = 24 }) => {
    return <Image src={iconList[icon]} alt={icon} width={size} height={size} />;
};
