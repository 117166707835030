import { useRouter } from 'next/router';
import React, { FC } from 'react';

import { DENGIMARKET_ENABLED_LANG, DENGIMARKET_LOGIN_URL, DENGIMARKET_OLX_URL } from '@/shared/config/app';
import { localesTitle } from '@/shared/constants/settings';
import { useTranslate } from '@/shared/hooks/use-translate';
import PersonIcon from '@/shared/icons/person-icon';
import PhoneEnabledIcon from '@/shared/icons/phone-enabled-icon';
import ShoppingBasketIcon from '@/shared/icons/shopping-basket-icon';
import { LinkText } from '@/shared/ui/link-text';
import { ELinkTextVariantType } from '@/shared/ui/link-text/types';
import { LocaleSwitcher } from '@/shared/ui/locale-switcher';

import * as Styled from './styled';

type TProps = {
    showPhoneModal?: () => void;
};

export const ActionSection: FC<TProps> = ({ showPhoneModal }) => {
    const { t } = useTranslate();
    const { locale, locales } = useRouter();

    return (
        <Styled.Wrapper>
            <Styled.LinksWrapper>
                {DENGIMARKET_ENABLED_LANG ? (
                    <LocaleSwitcher
                        activeLocale={locale as string}
                        locales={locales as string[]}
                        namedLocale={localesTitle}
                    />
                ) : (
                    <div className="space" />
                )}

                <LinkText onClick={showPhoneModal} variant={ELinkTextVariantType.UNBORDED}>
                    <PhoneEnabledIcon />

                    <span className="action-button-text">{t('Позвонить')}</span>
                </LinkText>

                <LinkText href={DENGIMARKET_OLX_URL} target="_blank" variant={ELinkTextVariantType.UNBORDED}>
                    <ShoppingBasketIcon />

                    <span className="action-button-text">{t('Магазин')}</span>
                </LinkText>

                <LinkText
                    href={DENGIMARKET_LOGIN_URL}
                    id="personal_button"
                    target="_blank"
                    variant={ELinkTextVariantType.GRADIENT_BRAND_GREEN}
                >
                    <div className="person-icon">
                        <PersonIcon />
                    </div>

                    <span>{t('Личный кабинет')}</span>
                </LinkText>
            </Styled.LinksWrapper>
        </Styled.Wrapper>
    );
};
