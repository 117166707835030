import { styled } from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;

export const WrapperContent = styled.div`
    flex-grow: 1;
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    width: 100%;
`;
