import { useRouter } from 'next/router';
import React, { FC } from 'react';

import { useAppSelector } from '@/store';

import { DENGIMARKET_LOGIN_URL } from '@/shared/config/app';
import { useTranslate } from '@/shared/hooks/use-translate';
import BurgerMenuIcon from '@/shared/icons/burger-menu-icon';
import LocationIcon from '@/shared/icons/location-icon';
import { numWord } from '@/shared/lib/string';
import { LinkText } from '@/shared/ui/link-text';
import { ELinkTextVariantType } from '@/shared/ui/link-text/types';
import LocationScrollLink from '@/shared/ui/location-scroll-link';

import { HeaderLogo } from '../header-logo';

import * as Styled from './styled';

type TProps = {
    showMenu?: () => void;
};

export const TitleSiteSection: FC<TProps> = ({ showMenu }) => {
    const officeCount = useAppSelector((state) => state.settings.officeCount);
    const route = useRouter();
    const { locale } = route;
    const { t } = useTranslate();
    const word = numWord(officeCount, ['Отделение', 'Отделения', 'Отделений']);

    const officeCountTitle =
        locale === 'ru' ? `${officeCount} ${word} ${t('headerTitle')}` : t('headerTitleKz', { count: officeCount });
    return (
        <Styled.SectionWrapper>
            <Styled.BurgerWrapper onClick={showMenu}>
                <BurgerMenuIcon />
            </Styled.BurgerWrapper>

            <HeaderLogo className="header-logo default" width={250} height={60} href="/" />

            <div className="title-content">
                <span className="bold-title-text">{officeCountTitle}</span>

                <span className="normal-title-text">{t('headerTitleOpeningHours')}</span>
                <LocationScrollLink
                    className="departments-link"
                    icon={<LocationIcon height={24} width={24} />}
                    onClick={() => (route.pathname === '/' ? null : route.push('/#office-map'))}
                    title={t('Отделения')}
                    to="office-map"
                    variant="simple"
                />

                <LinkText
                    className="loan-prolongation"
                    href={DENGIMARKET_LOGIN_URL}
                    id="button_header_prolongation"
                    target="_blank"
                    variant={ELinkTextVariantType.GRADIENT_BRAND_GREEN}
                >
                    <span>{t('Продлить микрокредит')}</span>
                </LinkText>
            </div>
        </Styled.SectionWrapper>
    );
};
