import { styled } from 'styled-components';

import { mq } from '@/styles/media.styled';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 100%;
    height: 440px;

    .employee-image {
        ${mq.lt.md} {
            display: none;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        padding: 42px 24px;
        text-align: center;

        .phone-input {
            margin-top: 42px;
        }

        .content-success {
            .title {
                color: #333f4f;
                font-size: 28px;
                line-height: 28px;
                padding-bottom: 12px;
            }

            .text {
                color: #858c94;
                font-size: 18px;
                line-height: 18px;
            }

            .sub-text {
                font-size: 18px;
                line-height: 24px;
                padding-top: 20px;
            }
        }

        .content-up-section {
            height: 100%;
            width: 100%;

            .content-title {
                font-weight: 500;
                font-size: 20px;
                line-height: 120%;
            }

            .content-text {
                font-weight: 500;
                font-size: 16px;
                line-height: 120%;
                color: #a9adbc;
                margin-top: 0.5rem;
                max-width: 300px;
            }
        }

        .content-down-section {
            height: 88px;
            width: 100%;

            .button-order-call {
                height: 48px;
                width: 100%;

                .button-text {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                }
            }

            .notation {
                font-weight: 400;
                font-size: 12px;
                line-height: 120%;
                color: #a9adbc;
                margin-top: 0.75rem;
            }
        }
    }

    .flex-col-center {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;
