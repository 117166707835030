import React, { FC } from 'react';

import { useTranslate } from '@/shared/hooks/use-translate';

import { FooterLogo } from '../footer-logo';

import * as Styled from './styled';

export const LogoTitleSection: FC = () => {
    const { t } = useTranslate();
    return (
        <Styled.Wrapper>
            <FooterLogo />
            <span className="title-text">{t('republicanPawnshops')}</span>
        </Styled.Wrapper>
    );
};
