import Link from 'next/link';
import { styled } from 'styled-components';

import { mq } from '@/styles/media.styled';

export const LinkWrapper = styled(Link)`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    .default {
        ${mq.lt.xs} {
            display: none;
        }

        display: block;
    }

    .compact {
        ${mq.lt.xs} {
            display: block;
        }

        display: none;
    }

    .alwaysDefault {
        display: block;
    }
`;
