import { TReadParamsProperties, ResourceService } from '@/shared/api';
import { axiosSiteClient } from '@/shared/api/site';
import { STATUS_CODE_CREATED } from '@/shared/constants/settings';
import { customCaptureException } from '@/shared/lib/custom-capture-exception';
import { TResponseErrorForm } from '@/shared/types/types';

import { TRequestParamCallbackForm } from '../types';

export const QuestionnairesService = ResourceService('questionnaires', axiosSiteClient);

export const sendQuestionnairesForm = async (formData: TRequestParamCallbackForm): Promise<boolean> => {
    try {
        const response = await QuestionnairesService.create<TResponseErrorForm | [], TReadParamsProperties>({
            data: formData,
        });
        if (response.statusCode === STATUS_CODE_CREATED) {
            return true;
        }
        return false;
    } catch (err) {
        customCaptureException(err, 'sendQuestionnairesForm');
        return false;
    }
};
