import { styled, css } from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    cursor: pointer;
    position: relative;
    width: 80px;
    height: 40px;
    border-radius: 20px;
    background-color: #fff;
    border: solid 1px #dadada;
    align-items: center;
    justify-content: space-between;
`;

const activeLangState = css`
    span {
        color: #fff;
    }

    a {
        border: 1px solid #63d07b;

        span {
            border-radius: 50%;
            color: #fff;
            content: '';
            width: 38px;
            height: 38px;
            background-image: linear-gradient(218.13deg, #63d07b 18.93%, #0bb6ba 94.11%);
            top: 5px;
            left: 5px;
            right: 0;
            animation: 0.3s ease-in both slide-back;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 3px solid #fff;
        }
    }
`;

export const LangWrapper = styled.div<{ isActive: boolean }>`
    span {
        font-size: 14px;
        color: #b5c0c3;
        font-weight: 500;
    }

    a {
        cursor: pointer;
        text-decoration: none;
        display: flex;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
    }

    ${({ isActive }) => isActive && activeLangState};
`;
