import React, { FC } from 'react';

import * as Styled from './styled';
import { TLinkTextProps, ELinkTextVariantType } from './types';

export const LinkText: FC<TLinkTextProps> = (props) => {
    const { children, href = '', variant = ELinkTextVariantType.BRAND_GREEN_UNBORDED, ...rest } = props;

    return (
        <Styled.Wrapper {...rest} href={href} variant={variant}>
            {children}
        </Styled.Wrapper>
    );
};
