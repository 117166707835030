import React from 'react';

import { TSvgIcon } from '@/shared/types/types';

const ShoppingBasketIcon: TSvgIcon = (props) => {
    const { height = 24, width = 24 } = props;

    return (
        <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22 9.00002H17.21L12.83 2.44002C12.64 2.16002 12.32 2.02002 12 2.02002C11.68 2.02002 11.36 2.16002 11.17 2.45002L6.79 9.00002H2C1.45 9.00002 1 9.45002 1 10C1 10.09 1.01 10.18 1.04 10.27L3.58 19.54C3.81 20.38 4.58 21 5.5 21H18.5C19.42 21 20.19 20.38 20.43 19.54L22.97 10.27L23 10C23 9.45002 22.55 9.00002 22 9.00002ZM12 4.80002L14.8 9.00002H9.2L12 4.80002ZM18.5 19L5.51 19.01L3.31 11H20.7L18.5 19ZM12 13C10.9 13 10 13.9 10 15C10 16.1 10.9 17 12 17C13.1 17 14 16.1 14 15C14 13.9 13.1 13 12 13Z"
                fill="url(#paint0_linear_704_1846)"
            />

            <defs>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint0_linear_729_1864"
                    x1="18.2857"
                    x2="6.46021"
                    y1="5.23984"
                    y2="22.7023"
                >
                    <stop stopColor="#63D07B" />

                    <stop offset="1" stopColor="#0BB6BA" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default ShoppingBasketIcon;
