import React, { FC, ReactElement } from 'react';

import LocationIcon from '@/shared/icons/location-icon';

import * as Styled from './styled';

export type TVariantScroll = 'gradient-brand-green' | 'simple';

type TProps = {
    className?: string;
    icon?: ReactElement;
    onClick?: () => void;
    title: string;
    to: string;
    variant?: TVariantScroll;
};

const LocationScrollLink: FC<TProps> = (props) => {
    const { className, icon = <LocationIcon />, onClick, title, to, variant = 'gradient-brand-green' } = props;

    return (
        <Styled.Wrapper
            className={className}
            duration={500}
            offset={-100}
            onClick={onClick}
            smooth={true}
            spy={true}
            to={to}
            variant={variant}
        >
            <div className="location-logo">{icon}</div>

            <span className="location-title">{title}</span>
        </Styled.Wrapper>
    );
};

export default LocationScrollLink;
