import { LinkProps } from 'next/dist/client/link';
import React from 'react';

export type TVariantType = 'unborded' | 'brand-green-unborded' | 'gradient-brand-green';

export enum ELinkTextVariantType {
    BRAND_GREEN_UNBORDED = 'BRAND_GREEN_UNBORDED',
    GRADIENT_BRAND_GREEN = 'GRADIENT_BRAND_GREEN',
    UNBORDED = 'UNBORDED',
}

type TLinkTextPropsBase = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps> &
    LinkProps & {
        children?: React.ReactNode;
    } & React.RefAttributes<HTMLAnchorElement>;

export type TLinkTextProps = Omit<TLinkTextPropsBase, 'as' | 'href'> & {
    as?: string;
    href?: string;
    variant?: ELinkTextVariantType;
};
