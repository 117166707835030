import Link from 'next/link';
import { styled, css } from 'styled-components';

import { TLinkTextProps, ELinkTextVariantType } from '@/shared/ui/link-text/types';

const brandGradientStyle = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px;
    gap: 8px;
    background: linear-gradient(218.13deg, #63d07b 18.93%, #0bb6ba 94.11%);
    border-radius: 8px;
    border: none;
    color: ${({ theme }) => theme.color.secondary};
    font-weight: 500;
    font-size: ${({ theme }) => theme.fontSize.md};
    line-height: 120%;
    cursor: pointer;
    height: 100%;

    &:hover {
        color: ${({ theme }) => theme.color.secondary};
    }
`;

const unbordedStyle = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding: 0;
    border: none;
    background: none;
    color: ${({ theme }) => theme.color.dark};
    font-size: ${({ theme }) => theme.fontSize.sm};
    line-height: 120%;
    cursor: pointer;
`;

const brandGreenUnbordedStyle = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 19px;
    border: none;
    color: ${({ theme }) => theme.color.primary};
    font-weight: 500;
    font-size: ${({ theme }) => theme.fontSize.sm};
    line-height: 120%;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
        color: ${({ theme }) => theme.color.primary};
    }

    background: none;
`;

const getButtonStyles = (variant: ELinkTextVariantType) => {
    switch (variant) {
        case ELinkTextVariantType.UNBORDED:
            return unbordedStyle;
        case ELinkTextVariantType.GRADIENT_BRAND_GREEN:
            return brandGradientStyle;
        case ELinkTextVariantType.BRAND_GREEN_UNBORDED:
            return brandGreenUnbordedStyle;
        default:
            return '';
    }
};

export const Wrapper = styled(Link)<TLinkTextProps>`
    ${({ variant }) => getButtonStyles(variant || ELinkTextVariantType.GRADIENT_BRAND_GREEN)};
`;
