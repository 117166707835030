import React, { FC } from 'react';

import { useAppDispatch, closeModal, showModal } from '@/store';

import { OrderCallForm } from '@/features/order-call-form';

import { LayoutDefault } from '@/shared/ui/layout/default';

import { ActionSection } from './actions-section';
import { HeaderLogo } from './header-logo';
import { ActionMenuSection } from './menu-section';
import * as Styled from './styled';
import { TitleSiteSection } from './title-site-section';

export const Header: FC = () => {
    const dispatch = useAppDispatch();

    const handleShowModalPhone = () => {
        dispatch(
            showModal({
                content: <OrderCallForm />,
                size: 'Rubber',
                type: 'Unboarded',
            }),
        );
    };

    const handleShowMenu = () => {
        dispatch(
            showModal({
                content: <ActionMenuSection showPhoneModal={handleShowModalPhone} />,
                size: 'Medium',
                title: (
                    <HeaderLogo
                        alwaysDefault
                        onClick={() => {
                            dispatch(closeModal());
                        }}
                        height={24}
                        href="/"
                        width={162}
                    />
                ),
                type: 'SideBar',
            }),
        );
    };

    return (
        <Styled.Wrapper>
            <LayoutDefault.Content>
                <Styled.ContentWrapper>
                    <TitleSiteSection showMenu={handleShowMenu} />
                    <ActionSection showPhoneModal={handleShowModalPhone} />
                </Styled.ContentWrapper>
            </LayoutDefault.Content>
        </Styled.Wrapper>
    );
};
