import { styled } from 'styled-components';

import { mq } from '@/styles/media.styled';

export const SectionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    width: 100%;

    .header-logo {
        ${mq.lt.lg} {
            width: fit-content;
            height: 24px;
        }
    }

    .departments-link {
        width: fit-content;
        ${mq.lt.sm} {
            display: none;
        }
    }

    .loan-prolongation {
        display: none;
        ${mq.lt.sm} {
            display: block;
            font-size: 14px;
        }
    }

    .title-content {
        display: flex;
        flex-direction: column;
        padding-left: 2.6rem;

        ${mq.lt.lg} {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: end;
            padding-left: 0;
        }

        .bold-title-text {
            font-size: ${({ theme }) => theme.fontSize.md};
            font-weight: 500;

            ${mq.lt.xl} {
                display: none;
            }

            margin-bottom: 0.37rem;
        }

        .normal-title-text {
            font-size: ${({ theme }) => theme.fontSize.sm};

            ${mq.lt.xl} {
                display: none;
            }

            margin-bottom: 0.6rem;
        }

        .location-logo {
            margin-right: 5px;
        }

        .location-title {
            :hover {
                color: #14ae51;
            }
        }
    }
`;

export const BurgerWrapper = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;

    ${mq.gt.md} {
        display: none;
    }
`;
