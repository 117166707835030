import { styled } from 'styled-components';

import { mq } from '@/styles/media.styled';

export const Wrapper = styled.div`
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 1408px;

    ${mq.gt.sm} {
        padding-left: 32px;
        padding-right: 32px;
    }

    ${mq.gt.md} {
        padding-left: 64px;
        padding-right: 64px;
    }

    ${mq.lt.md} {
        padding-left: 12px;
        padding-right: 12px;
    }
    ${mq.gt.xl} {
        padding: 0;
    }
`;
